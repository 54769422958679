/* FONTS */

@font-face {
  font-family: GrimoireOfDeath;
  src: url(../fonts/xxii-totenkult.otf);
}

/* STYLING */

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: GrimoireOfDeath, cursive;
  color: white;
  font-size: .92em;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 24px;
  border-radius: 12px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

html {
  width: 100%;
  overflow: hidden !important;
}

body {
  height: 100%;
  position: fixed;
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
}

h1 {
  display: none !important;
}

#container {
  overflow: hidden !important;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: black;
  font-size: 1.5rem;
  display: flex;
  justify-content: flex-end;
}

#body-container {
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#loading-image-container {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: loading-image-container-disappear;
  animation-duration: 2s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

#loading-image {
  max-width: 40vw;
  max-height: 40vh;
  animation-name: loading-image-appear;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

#body-container::-webkit-scrollbar {
  display: none;
}

.no-scroll {
  overflow: hidden !important;
}

.navigation-menu {
  width: 60%;
  right: -60%;
  transition: right .5s;
  height: 100%;
  background-color: black;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 90;
}

.navigation-menu-visible {
  right: 0;
  transition: right .5s;
}

#navigation-menu-background {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 80;
  background-color: transparent;
  backdrop-filter: blur(2px);
  opacity: 0;
  animation: navigation-menu-background-fade-in 1s forwards;
}

.navigation-menu > div{
  font-size: 1.5em;
  box-shadow: 0px 2px white;
  margin-top: 1.5em;
  padding-inline: .25em;
}

.divider {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-block: 1em;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 2em;
}

#menu-bar {
  width: 50%;
  height: 150%;
  position: absolute;
  z-index: 99;
  background-color: red;
}

#navbar {
  width: 100%;
  height: 80px;
  position: fixed;
  overflow: hidden;
  z-index: 100;
  top: 0;
  background-color: black;
} 

#navbar-buttons-container {
  width: 100%;
  height: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
}

.visibility-animation-on {
  opacity: 0;
  animation: navbar-fade-in 1s forwards;
}

.visibility-animation-off {
  opacity: 1;
  animation: navbar-fade-out 1s forwards;
}

.navbar-display-none {
  display: none;
}

.navbar-display-flex {
  display: flex;
}

.navbar-align-items-end {
  justify-content: flex-end !important;
  padding-right: .5em;
}

.hamburger {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hamburgerRotate.active {
  transform: rotate(45deg);
}

.line {
  fill:none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: white;
  stroke-width: 5.5;
  stroke-linecap:round;
}

.hamburger .top {
  stroke-dasharray: 40 160;
}

.hamburger .middle {
  stroke-dasharray: 40 142;
  transform-origin: 50%;
  transition: transform 400ms;
}

.hamburger .bottom {
  stroke-dasharray: 40 85;
  transform-origin: 50%;
  transition: transform 400ms, stroke-dashoffset 400ms;
}

.hamburger.active .top {
  stroke-dashoffset: -64px;
}

.hamburger.active .middle {
  transform: rotate(90deg);
}

.hamburger.active .bottom {
  stroke-dashoffset: -64px;
}

#navbar-menu-button {
  background-color: transparent;
  cursor: pointer;
}

#loading-logo {
  max-width: 200px;
  max-height: 200px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  opacity: 0;
  animation: fade-in-logo 4s forwards 2s, fade-out-logo 4s forwards 6s;
}

#navbar-logo {
  position: fixed;
  z-index: 101;
  width: auto;
  height: 100px;
  left: 0;
  transform: translate(30px, 15px);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.navigation-button {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-inline: 1em;
  cursor: pointer;
}

.navigation-button:hover {
  transform: scale(1.2);
}

#jind-cover {
  width: auto;
  height: 100vh;
}

#scroll-down {-webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  width: 100%;
  height: auto;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

#scroll-down-container {
  position: relative;
  top: -150px;
  width: 75px;
}

.scroll-down-visibility-animation-on {
  opacity: 0;
  animation: scroll-down-fade-in .5s forwards;
}

.disable-drag {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.scroll-down-visibility-animation-off {
  opacity: 1;
  animation: scroll-down-fade-out .5s forwards;
}

#copyright {
  height: auto; 
  width: 100%;
  color: #666;
  font-size: .8em;
  text-align: center;
  margin-block: 2em;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h2 {
  width: 100%;
  text-align: center;
  font-size: 2em;
  margin-block: 1em;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#section-about-us {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#section-about-us > p {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2em;
  padding-inline: 2em;
  text-indent: 2em;
}

#section-about-us > p:not(:first-of-type) {
  margin-top: .5em;
}

#subsection-members {
  margin-top: 2em;
  margin-bottom: 0;
}

#subsection-former-members {
  margin-top: 2em;
  margin-bottom: 0;
}

.member-box {
  margin-top: 1.5em;
  display: flex;
  width: 80%;
  height: auto;
}

.member-box > img {
  width: 50%;
  max-width: 500px;
  height: auto;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.member-box > p {
  padding-top: 1.5em;
  font-size: 1.5em;
  width: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.member-box > p:nth-child(even) {
  padding-left: 1.5em;
  text-align: left;
}

.member-box > p:nth-child(odd) {
  padding-right: 1.5em;
  text-align: right;
}

.member-box-mobile-view {
  margin-top: 3em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.member-box-mobile-view > p {
  text-align: center !important;
}

.member-box-mobile-view > img {
  width: 100%;
  max-width: 500px;
}

#latest-release-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 1em;
}

iframe {
  max-width: 100% !important;
  border-radius: 15px;
  box-shadow: 0px 0px 40px 0px rgba(255, 255, 255, 0.3);
}

#section-events {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#section-events > h2:nth-of-type(2) {
  margin-top: 1.5em;
}

.event-poster-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5em;
  width: 100%;
  max-width: 600px;
  height: auto;
}

.event-poster-container {
  width: 80%;
  border-radius: 20px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-carousel-poster {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.image-carousel-arrow {
  font-size: 2em;
  color: white;
  cursor: pointer;
}

.image-carousel-arrow:hover {
  transform: scale(1.2);
}

.events-container {
  width: auto;
  font-size: 1.2em;
  display: flex;
  gap: .5em;
  flex-direction: column;
}

#no-future-events-message {
  font-size: 1.5em;
}

#no-past-events-message {
  font-size: 1.5em;
}

.event-line {
  display: flex;
  justify-content: flex-start;
  margin-left: 1.5em;
  margin-right: 1.5em;
  width: calc(100% - 3em);
  padding-inline: 1em;
  box-shadow: 0px .5px white;
  padding-top: .5em;
}

.event-line > p:nth-child(1) {
  margin-right: 1em;
}

.event-line > p:nth-child(2) {
  flex-grow: 1;
  white-space: pre-line;
}

.event-line > p:nth-child(3) {
  width: auto;
  margin-left: 2.5em;
}

#section-merchandise {
  width: 100%;
}

#merch-container {
  display: flex;
  justify-content: center;
}

#merch-container > img {
  padding: 1.5em;
  aspect-ratio: initial;
  max-width: min(300px, 80%);
  transition: transform .75s, filter .5s;
}

#merch-container > img:hover {
  transform: scale(1.1);
  filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.4));
}

#section-contact {
  width: 100%;
}

#section-contact > p {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#contact-container-mobile {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
}

#contact-container-mobile > p {
  font-size: 1.5em;
}

#other-pages-container-mobile {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.link-button {
  width: 40px;
  height: auto;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.link-button:hover {
  transform: scale(1.2);
}

#contact-container {
  display: flex;
  width: 100%;
  height: auto;
}

#contact-container-left-panel {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contact-container-right-panel {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#contact-container-right-panel > p {
  font-size: 1.5em;
}

#contact-form {
  width: 70%;
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
}

#contact-form > label {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#contact-form > input {
  background-color: black;
  border-radius: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  outline: none;
  border: 2px solid #666;
  padding-block: .25em;
  padding-inline: .5em;
  font-size: 1.2rem;
  margin-bottom: 1em;
}

#contact-form > input:active, #contact-form > input:focus {
  border: 2px solid white;
}

#contact-form > textarea {
  resize: none;
  background-color: black;
  border-radius: 10px;
  outline: none;
  border: 2px solid #666;
  padding-block: .25em;
  padding-inline: .5em;
  font-size: 1.2rem;
  margin-bottom: 1em;
  height: 7.1rem;
}

#contact-form > textarea:active, #contact-form > textarea:focus {
  border: 2px solid white;
}

#contact-form > button {
  width: 50%;
}

#contact-form > input:last-of-type {
  margin-top: 1em;
  width: auto;
  padding-inline: 1em;
  -webkit-tap-highlight-color: transparent;
  align-self: center;
  cursor: pointer;
  border: 2px solid white;
  background-color: black;
  color: white;
  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
  transition: color 1s;
}

#contact-form > input:last-of-type:hover {
  background-color: white;
  color: black;
  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
  transition: color 1s;
}

.input-disabled {
  border: 2px solid black !important;
  color: black !important;
  cursor: default !important;
}

.input-disabled:hover {
  background-color: black !important;
  border: 2px solid black !important;
  color: black !important;
}

#contact-form > p {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: .7em;
  color: #666;
}

.notification-modal-background {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: .5em;
  box-shadow: 0px 0px 10px -2px white;
  background-color: black;
  border-radius: 10px;
  padding: .45em;
}

.notification-modal > p {
  margin-inline: 1em;
  margin-block: 1em;
  font-size: 1.2em;
}

.notification-modal-close-button {
  width: 25px;
  height: auto;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.notification-modal-close-button:hover {
  transform: scale(1.2);
}

/* MEDIA QUERIES */

@media only screen and (max-width: 900px) {
  #container {
    font-size: 1.2rem;
  }

  #jind-cover {
    object-fit: cover;
    height: 100%;
  }

  #section-about-us > p {
    padding-inline: .5em;
    text-indent: 1.5em;
  }

  #navbar {
    height: 60px;
  }

  #navbar-logo {
    width: auto;
    height: 75px;
    left: 0;
    transform: translate(15px, 10px);
  }

  #navbar-buttons-container {
    height: 60px;
  }

  #copyright {
    font-size: .7em;
    margin-bottom: 6em;
  }

  .divider {
    margin-block: 1.5em;
  }

  #contact-form {
    width: 90%;
  }

  .member-box > p {
    padding-inline: 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  .navigation-button {
    font-size: 1.2em !important;
  }
}

/* KEYFRAMES */

@keyframes navbar-fade-in {
  to {
    opacity: 1;
  }
}

@keyframes navbar-fade-out {
  to {
    opacity: 0;
  }
}
@keyframes fade-in-logo {
  to {
    opacity: 1;
  }
}

@keyframes fade-out-logo {
  to {
    opacity: 0;
    display: none;
  }
}

@keyframes scroll-down-fade-in {
  to {
    opacity: 1;
  }
}

@keyframes navigation-menu-background-fade-in {
  to {
    opacity: 1;
  }
}

@keyframes scroll-down-fade-out {
  to {
    opacity: 0;
  }
}

@keyframes loading-image-container-disappear {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
      display: none;
  }
}

@keyframes loading-image-appear {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}